class Perm {
    /** 装车单_查看 */
    /** 查看装车单 */
    public static SPO_R: string = "SPO.R";

    /** 装车单_财务放行 */
    /** 财务确认放行装车单 */
    public static SPO_SE: string = "SPO.SE";

    /** 装车单_出厂核查 */
    /** 装车单出厂核查 */
    public static SPO_CO: string = "SPO.CO";

    /** 装车单_荒料_创建 */
    /** 创建荒料装车单 */
    public static SPO_B_C: string = "SPO.B.C";

    /** 装车单_荒料_更新 */
    /** 更新荒料装车单 */
    public static SPO_B_U: string = "SPO.B.U";

    /** 装车单_荒料_取消 */
    /** 取消荒料装车单 */
    public static SPO_B_CL: string = "SPO.B.CL";

    /** 装车单_荒料_装车完成 */
    /** 确认荒料装车单装车完成 */
    public static SPO_B_FL: string = "SPO.B.FL";

    /** 装车单_荒料_导出 */
    /** 导出荒料装车单 */
    public static SPO_B_E: string = "SPO.B.E";

    /** 装车单_大板_创建 */
    /** 创建大板装车单 */
    public static SPO_S_C: string = "SPO.S.C";

    /** 装车单_大板_更新 */
    /** 更新大板装车单 */
    public static SPO_S_U: string = "SPO.S.U";

    /** 装车单_大板_取消 */
    /** 取消大板装车单 */
    public static SPO_S_CL: string = "SPO.S.CL";

    /** 装车单_大板_装车完成 */
    /** 确认大板装车单装车完成 */
    public static SPO_S_FL: string = "SPO.S.FL";

    /** 装车单_大板_导出 */
    /** 导出大板装车单 */
    public static SPO_S_E: string = "SPO.S.E";

    /** 装车单_工程板_创建 */
    /** 创建工程板装车单 */
    public static SPO_T_C: string = "SPO.T.C";

    /** 装车单_工程板_更新 */
    /** 更新工程板装车单 */
    public static SPO_T_U: string = "SPO.T.U";

    /** 装车单_工程板_取消 */
    /** 取消工程板装车单 */
    public static SPO_T_CL: string = "SPO.T.CL";

    /** 装车单_工程板_装车完成 */
    /** 确认工程板装车单装车完成 */
    public static SPO_T_FL: string = "SPO.T.FL";

    /** 装车单_工程板_导出 */
    /** 导出工程板装车单 */
    public static SPO_T_E: string = "SPO.T.E";

    /** 荒料_查看 */
    /** 查看荒料 */
    public static B_R: string = "B.R";

    /** 荒料_报废 */
    /** 报废荒料 */
    public static B_D: string = "B.D";

    /** 荒料_导入 */
    /** 导入荒料 */
    public static B_I: string = "B.I";

    /** 荒料_导出 */
    /** 导出荒料 */
    public static B_E: string = "B.E";

    /** 荒料_收料信息_创建 */
    /** 荒料收料 */
    public static B_MI_C: string = "B.MI.C";

    /** 荒料_入库信息_创建 */
    /** 荒料入库 */
    public static B_FI_C: string = "B.FI.C";

    /** 荒料_基本信息_更新 */
    /** 更新荒料基本信息 */
    public static B_BI_U: string = "B.BI.U";

    /** 荒料_成本信息_更新 */
    /** 更新荒料成本信息 */
    public static B_C_U: string = "B.C.U";

    /** 荒料_成本信息_查看 */
    /** 查看荒料成本信息 */
    public static B_C_R: string = "B.C.R";

    /** 荒料_石材种类_更新 */
    /** 更新荒料石材种类 */
    public static B_CA_U: string = "B.CA.U";

    /** 荒料_存放区域_更新 */
    /** 更新荒料存放区域 */
    public static B_SA_U: string = "B.SA.U";

    /** 荒料_参考价_查看 */
    /** 查看荒料参考价 */
    public static B_RP_R: string = "B.RP.R";

    /** 荒料_修边记录_创建 */
    /** 创建荒料修边记录 */
    public static B_TD_C: string = "B.TD.C";

    /** 荒料_修边记录_更新 */
    /** 更新荒料修边记录 */
    public static B_TD_U: string = "B.TD.U";

    /** 荒料_修边记录_删除 */
    /** 删除荒料修边记录 */
    public static B_TD_D: string = "B.TD.D";

    /** 荒料_修边记录_删除 */
    /** 审批荒料修边记录 */
    public static B_TD_AP: string = "B.TD.AP";

    /** 荒料_规划方案_创建 */
    /** 查看荒料规划方案 */
    public static B_PR_R: string = "B.PR.R";

    /** 荒料_规划方案_创建或更新 */
    /** 创建或更新荒料规划方案 */
    public static B_PR_CU: string = "B.PR.CU";

    /** 荒料_规划方案_删除 */
    /** 删除荒料规划方案 */
    public static B_PR_D: string = "B.PR.D";

    /** 大板_查看 */
    /** 查看大板 */
    public static S_R: string = "S.R";

    /** 大板_入库 */
    /** 大板入库 */
    public static S_S: string = "S.S";

    /** 大板_更新 */
    /** 更新大板 */
    public static S_U: string = "S.U";

    /** 大板_报废 */
    /** 报废大板 */
    public static S_D: string = "S.D";

    /** 大板_导出 */
    /** 导出大板 */
    public static S_E: string = "S.E";

    /** 大板_导入 */
    /** 导入大板 */
    public static S_I: string = "S.I";

    /** 大板_导入重组数据 */
    /** 导入重组数据大板 */
    public static S_IRD: string = "S.IRD";

    /** 大板_导入盘点文件获取对比结果 */
    /** 导入盘点文件获取对比结果 */
    public static S_ICIEF: string = "S.ICIEF";

    /** 大板_参考价_查看 */
    /** 查看大板参考价 */
    public static S_RP_R: string = "S.RP.R";

    /** 大板_等级_更新 */
    /** 更新大板等级 */
    public static S_G_U: string = "S.G.U";

    /** 大板_存放区域_更新 */
    /** 更新大板存放区域 */
    public static S_SA_U: string = "S.SA.U";

    /** 大板_生产信息_更新 */
    /** 更新生产状态的大板信息 */
    public static S_MI_U: string = "S.MI.U";

    /** 大板_码单_导出 */
    /** 导出大板码单 */
    public static S_CS_E: string = "S.CS.E";

    /** 大板_磨抛图片_采集 */
    /** 采集大板磨抛图片 */
    public static S_IM_CP: string = "S.IM.CP";

    /** 大板_磨抛数据_导入 */
    /** 导入大板磨抛数据 */
    public static S_PQD_I: string = "S.PQD.I";

    /** 工程板_查看 */
    /** 查看工程板 */
    public static T_R: string = "T.R";

    /** 工程板_删除 */
    /** 删除工程板 */
    public static T_D: string = "T.D";

    /** 工程板_导出 */
    /** 导出工程板 */
    public static T_E: string = "T.E";

    /** 工程板_价格_查看 */
    /** 查看工程板价格 */
    public static T_P_R: string = "T.P.R";

    /** 工程板_规格数据_更新 */
    /** 更新工程板规格数据 */
    public static T_SD_U: string = "T.SD.U";

    /** 工程板_规格数据_导入 */
    /** 导入工程板规格数据 */
    public static T_SD_I: string = "T.SD.I";

    /** 工程板_生产数据_更新 */
    /** 更新工程板生产数据 */
    public static T_MD_U: string = "T.MD.U";

    /** 工程板_生产数据_导入 */
    /** 导入工程板生产数据 */
    public static T_MD_I: string = "T.MD.I";

    /** 工程板_图片_采集 */
    /** 采集工程板图片 */
    public static T_IM_CP: string = "T.IM.CP";

    /** 工程板包装箱_查看 */
    /** 查看工程板包装箱 */
    public static TPC_R: string = "TPC.R";

    /** 工程板包装箱_入库 */
    /** 工程板包装箱入库 */
    public static TPC_S: string = "TPC.S";

    /** 工程板包装箱_导出 */
    /** 导出工程板包装箱 */
    public static TPC_E: string = "TPC.E";

    /** 工程板包装箱_存放区域_更新 */
    /** 更新工程板包装箱存放区域 */
    public static TPC_SA_U: string = "TPC.SA.U";

    /** 调拨单_大板_查看 */
    /** 查看大板调拨单 */
    public static TO_S_R: string = "TO.S.R";

    /** 调拨单_大板_创建 */
    /** 创建大板调拨单 */
    public static TO_S_C: string = "TO.S.C";

    /** 调拨单_大板_更新 */
    /** 更新大板调拨单 */
    public static TO_S_U: string = "TO.S.U";

    /** 调拨单_大板_取消 */
    /** 取消大板调拨单 */
    public static TO_S_CL: string = "TO.S.CL";

    /** 调拨单_大板_审批 */
    /** 审批大板调拨单 */
    public static TO_S_AP: string = "TO.S.AP";

    /** 调拨单_大板_导出 */
    /** 导出大板调拨单 */
    public static TO_S_E: string = "TO.S.E";

    /** 调拨单_工程板_查看 */
    /** 查看工程板调拨单 */
    public static TO_T_R: string = "TO.T.R";

    /** 调拨单_工程板_创建 */
    /** 创建工程板调拨单 */
    public static TO_T_C: string = "TO.T.C";

    /** 调拨单_工程板_更新 */
    /** 更新工程板调拨单 */
    public static TO_T_U: string = "TO.T.U";

    /** 调拨单_工程板_取消 */
    /** 取消工程板调拨单 */
    public static TO_T_CL: string = "TO.T.CL";

    /** 调拨单_工程板_审批 */
    /** 审批工程板调拨单 */
    public static TO_T_AP: string = "TO.T.AP";

    /** 调拨单_工程板_导出 */
    /** 导出工程板调拨单 */
    public static TO_T_E: string = "TO.T.E";

    /** 调拨单_荒料_查看 */
    /** 查看荒料调拨单 */
    public static TO_B_R: string = "TO.B.R";

    /** 调拨单_荒料_创建 */
    /** 创建荒料调拨单 */
    public static TO_B_C: string = "TO.B.C";

    /** 调拨单_荒料_更新 */
    /** 更新荒料调拨单 */
    public static TO_B_U: string = "TO.B.U";

    /** 调拨单_荒料_取消 */
    /** 取消荒料调拨单 */
    public static TO_B_CL: string = "TO.B.CL";

    /** 调拨单_荒料_审批 */
    /** 审批荒料调拨单 */
    public static TO_B_AP: string = "TO.B.AP";

    /** 调拨单_荒料_导出 */
    /** 导出荒料调拨单 */
    public static TO_B_E: string = "TO.B.E";

    /** 销售订单_查看 */
    /** 查看销售订单 */
    public static SO_R: string = "SO.R";

    /** 销售订单_创建 */
    /** 创建销售订单 */
    public static SO_C: string = "SO.C";

    /** 销售订单_更新 */
    /** 更新销售订单 */
    public static SO_U: string = "SO.U";

    /** 销售订单_取消 */
    /** 取消销售订单 */
    public static SO_CL: string = "SO.CL";

    /** 销售订单_完成 */
    /** 完成销售订单 */
    public static SO_CT: string = "SO.CT";

    /** 销售订单_分享 */
    /** 分享销售订单 */
    public static SO_SH: string = "SO.SH";

    /** 销售订单_审批 */
    /** 审批销售订单 */
    public static SO_AP: string = "SO.AP";

    /** 销售订单_导出 */
    /** 导出销售订单 */
    public static SO_E: string = "SO.E";

    /** 销售订单_忽略大扎整颗料销售规则 */
    /** 忽略库存大扎整颗料销售规则 */
    public static SO_IWR: string = "SO.IWR";

    /** 销售订单_优先级_更新 */
    /** 更新销售订单优先级 */
    public static SO_PT_U: string = "SO.PT.U";

    /** 销售订单_状态_更新 */
    /** 更新销售订单状态 */
    public static SO_ST_U: string = "SO.ST.U";

    /** 销售订单_销售明细_价格_更新 */
    /** 更新销售明细价格 */
    public static SO_SOD_P_U: string = "SO.SOD.P.U";

    /** 销售订单_价格信息_查看 */
    /** 查看销售订单价格信息 */
    public static SO_P_R: string = "SO.P.R";

    /** 销售订单_冻结类型_更新 */
    /** 更新销售订单冻结类型 */
    public static SO_RT_U: string = "SO.RT.U";

    /** 未跟踪产品清单_查看 */
    /** 查看未跟踪产品清单 */
    public static UPS_R: string = "UPS.R";

    /** 未跟踪产品清单_创建 */
    /** 创建未跟踪产品清单 */
    public static UPS_C: string = "UPS.C";

    /** 未跟踪产品清单_更新 */
    /** 更新未跟踪产品清单 */
    public static UPS_U: string = "UPS.U";

    /** 未跟踪产品清单_删除 */
    /** 删除未跟踪产品清单 */
    public static UPS_D: string = "UPS.D";

    /** 生产工单_查看 */
    /** 查看生产工单 */
    public static WO_R: string = "WO.R";

    /** 生产工单_创建 */
    /** 创建生产工单 */
    public static WO_C: string = "WO.C";

    /** 生产工单_取消 */
    /** 取消生产工单 */
    public static WO_CL: string = "WO.CL";

    /** 生产工单_更新 */
    /** 更新生产工单 */
    public static WO_U: string = "WO.U";

    /** 生产工单_完成 */
    /** 完成生产工单 */
    public static WO_CT: string = "WO.CT";

    /** 生产工单_导出 */
    /** 导出生产工单 */
    public static WO_E: string = "WO.E";

    /** 生产工单_领料单_查看 */
    /** 查看生产工单领料单 */
    public static WO_MR_R: string = "WO.MR.R";

    /** 生产工单_领料单_创建 */
    /** 创建生产工单领料单 */
    public static WO_MR_C: string = "WO.MR.C";

    /** 生产工单_领料单_审批 */
    /** 审批生产工单领料单 */
    public static WO_MR_AP: string = "WO.MR.AP";

    /** 生产工单_修边数据_更新 */
    /** 更新生产工单修边数据 */
    public static WO_TD_U: string = "WO.TD.U";

    /** 生产工单_修边数据_删除 */
    /** 删除生产工单修边记录 */
    public static WO_TD_D: string = "WO.TD.D";

    /** 生产工单_大切数据_更新 */
    /** 更新生产工单大切数据 */
    public static WO_SD_U: string = "WO.SD.U";

    /** 生产工单_修边质检数据_更新 */
    /** 更新生产工单修边质检数据 */
    public static WO_TQD_U: string = "WO.TQD.U";

    /** 生产工单_大切质检数据_更新 */
    /** 更新生产工单大切质检数据 */
    public static WO_SQD_U: string = "WO.SQD.U";

    /** 生产工单_生产工序_更新 */
    /** 更新生产工单生产工序 */
    public static WO_MP_U: string = "WO.MP.U";

    /** 生产工单_补胶数据_更新 */
    /** 更新生产工单补胶数据 */
    public static WO_FD_U: string = "WO.FD.U";

    /** 生产工单_补胶质检数据_更新 */
    /** 更新生产工单补胶质检数据 */
    public static WO_FQD_U: string = "WO.FQD.U";

    /** 生产工单_磨抛质检数据_更新 */
    /** 更新生产工单磨抛质检数据 */
    public static WO_PQD_U: string = "WO.PQD.U";

    /** 生产工单_磨抛数据_更新 */
    /** 更新生产工单磨抛数据 */
    public static WO_PD_U: string = "WO.PD.U";

    /** 生产工单_所属工厂_更新 */
    /** 更新所属工厂 */
    public static WO_F_U: string = "WO.F.U";

    /** 生产工单_附件_更新 */
    /** 更新生产工单附件 */
    public static WO_AT_U: string = "WO.AT.U";

    /** 大板领料单_查看 */
    /** 查看大板领料单 */
    public static SCOR_R: string = "SCOR.R";

    /** 大板领料单_创建 */
    /** 创建大板领料单 */
    public static SCOR_C: string = "SCOR.C";

    /** 大板领料单_取消 */
    /** 取消大板领料单 */
    public static SCOR_CL: string = "SCOR.CL";

    /** 大板领料单_审批 */
    /** 审批大板领料单 */
    public static SCOR_AP: string = "SCOR.AP";

    /** 大板退料单_查看 */
    /** 查看大板退料单 */
    public static SRR_R: string = "SRR.R";

    /** 大板退料单_创建 */
    /** 创建大板退料单 */
    public static SRR_C: string = "SRR.C";

    /** 大板退料单_取消 */
    /** 取消大板退料单 */
    public static SRR_CL: string = "SRR.CL";

    /** 大板退料单_审批 */
    /** 审批大板退料单 */
    public static SRR_AP: string = "SRR.AP";

    /** 大板等级变更单_查看 */
    /** 查看大板等级变更单 */
    public static SGCR_R: string = "SGCR.R";

    /** 大板等级变更单_创建或更新 */
    /** 创建或更新大板等级变更单 */
    public static SGCR_CU: string = "SGCR.CU";

    /** 大板等级变更单_取消 */
    /** 取消大板等级变更单 */
    public static SGCR_CL: string = "SGCR.CL";

    /** 大板等级变更单_审批 */
    /** 审批大板等级变更单 */
    public static SGCR_AP: string = "SGCR.AP";

    /** 报表_销售订单_查看 */
    /** 查看销售订单报表 */
    public static RPT_SO_R: string = "RPT.SO.R";

    /** 报表_荒料_入库_查看 */
    /** 查看荒料入库报表 */
    public static RPT_B_S_R: string = "RPT.B.S.R";

    /** 报表_荒料_库存_查看 */
    /** 查看荒料库存报表 */
    public static RPT_B_I_R: string = "RPT.B.I.R";

    /** 报表_生产_查看 */
    /** 查看生产报表 */
    public static RPT_WO_R: string = "RPT.WO.R";

    /** 报表_大板_库存_查看 */
    /** 查看大板库存报表 */
    public static RPT_S_I_R: string = "RPT.S.I.R";

    /** 报表_大板_入库明细_导出 */
    /** 导出大板入库明细报表 */
    public static RPT_S_S_E: string = "RPT.S.S.E";

    /** 报表_大板_领退料明细_导出 */
    /** 导出大板领退料明细报表 */
    public static RPT_S_CR_E: string = "RPT.S.CR.E";

    /** 报表_大板_装车明细_导出 */
    /** 导出大板装车明细报表 */
    public static RPT_S_LD_E: string = "RPT.S.LD.E";

    /** 报表_大板_生产汇总_导出 */
    /** 导出大板生产汇总报表 */
    public static RPT_S_MS_E: string = "RPT.S.MS.E";

    /** 报表_大板_毛板生产明细_导出 */
    /** 导出大板毛板生产明细报表 */
    public static RPT_S_RMD_E: string = "RPT.S.RMD.E";

    /** 报表_大板_光板生产明细_导出 */
    /** 导出大板光板生产明细报表 */
    public static RPT_S_PMD_E: string = "RPT.S.PMD.E";

    /** 报表_结算单_查看 */
    /** 查看结算报表 */
    public static RPT_SS_R: string = "RPT.SS.R";

    /** 报表_仪表板_查看 */
    /** 查看仪表板报表 */
    public static RPT_DB_R: string = "RPT.DB.R";

    /** 报表_销售成本利润核算_导出 */
    /** 导出销售成本利润核算报表 */
    public static RPT_SOCP_E: string = "RPT.SOCP.E";

    /** 报表_代加工核算_导出 */
    /** 导出代加工核算报表 */
    public static RPT_OEM_E: string = "RPT.OEM.E";

    /** 结算单_查看 */
    /** 查看结算单 */
    public static SS_R: string = "SS.R";

    /** 结算单_创建 */
    /** 创建结算单 */
    public static SS_C: string = "SS.C";

    /** 结算单_完成 */
    /** 完成结算单 */
    public static SS_CF: string = "SS.CF";

    /** 结算单_取消 */
    /** 取消结算单 */
    public static SS_CL: string = "SS.CL";

    /** 结算单_审批 */
    /** 审批结算单 */
    public static SS_AP: string = "SS.AP";

    /** 结算单_导出 */
    /** 导出结算单 */
    public static SS_E: string = "SS.E";

    /** 结算单_基本信息（除财务填写的结算数据外）_更新 */
    /** 更新结算单基本信息（除财务填写的结算数据外） */
    public static SS_BI_U: string = "SS.BI.U";

    /** 结算单_结算信息_更新 */
    /** 更新结算单结算信息 */
    public static SS_SI_U: string = "SS.SI.U";

    /** 存入扣减款项_查看 */
    /** 查看存入扣减款项 */
    public static DII_R: string = "DII.R";

    /** 存入扣减款项_创建 */
    /** 创建存入扣减款项 */
    public static DII_C: string = "DII.C";

    /** 存入扣减款项_更新 */
    /** 更新存入扣减款项 */
    public static DII_U: string = "DII.U";

    /** 客户_查看 */
    /** 查看客户 */
    public static CU_R: string = "CU.R";

    /** 客户_创建 */
    /** 创建客户 */
    public static CU_C: string = "CU.C";

    /** 客户_更新 */
    /** 更新客户 */
    public static CU_U: string = "CU.U";

    /** 客户_余额_查看 */
    /** 查看客户余额 */
    public static CU_B_R: string = "CU.B.R";

    /** 跟进记录_查看 */
    /** 查看跟进记录 */
    public static FUR_R: string = "FUR.R";

    /** 跟进记录_创建 */
    /** 创建跟进记录 */
    public static FUR_C: string = "FUR.C";

    /** 跟进记录_更新 */
    /** 更新跟进记录 */
    public static FUR_U: string = "FUR.U";

    /** 跟进记录_删除 */
    /** 删除跟进记录 */
    public static FUR_D: string = "FUR.D";

    /** 收货地址_查看 */
    /** 查看收货地址 */
    public static SAD_R: string = "SAD.R";

    /** 收货地址_创建 */
    /** 创建收货地址 */
    public static SAD_C: string = "SAD.C";

    /** 收货地址_更新 */
    /** 更新收货地址 */
    public static SAD_U: string = "SAD.U";

    /** 石材种类_查看 */
    /** 查看石材种类 */
    public static SC_R: string = "SC.R";

    /** 石材种类_创建 */
    /** 创建石材种类 */
    public static SC_C: string = "SC.C";

    /** 石材种类_更新 */
    /** 更新石材种类 */
    public static SC_U: string = "SC.U";

    /** 石材等级_查看 */
    /** 查看石材等级 */
    public static SG_R: string = "SG.R";

    /** 石材等级_创建 */
    /** 创建石材等级 */
    public static SG_C: string = "SG.C";

    /** 石材等级_更新 */
    /** 更新石材等级 */
    public static SG_U: string = "SG.U";

    /** 工艺_查看 */
    /** 查看工艺 */
    public static P_R: string = "P.R";

    /** 工艺_创建 */
    /** 创建工艺 */
    public static P_C: string = "P.C";

    /** 工艺_更新 */
    /** 更新工艺 */
    public static P_U: string = "P.U";

    /** 矿山_查看 */
    /** 查看矿山 */
    public static Q_R: string = "Q.R";

    /** 矿山_创建 */
    /** 创建矿山 */
    public static Q_C: string = "Q.C";

    /** 矿山_更新 */
    /** 更新矿山 */
    public static Q_U: string = "Q.U";

    /** 库存区域_查看 */
    /** 查看库存区域 */
    public static SA_R: string = "SA.R";

    /** 库存区域_创建 */
    /** 创建库存区域 */
    public static SA_C: string = "SA.C";

    /** 库存区域_更新 */
    /** 更新库存区域 */
    public static SA_U: string = "SA.U";

    /** 包装方式_查看 */
    /** 查看包装方式 */
    public static PM_R: string = "PM.R";

    /** 包装方式_创建 */
    /** 创建包装方式 */
    public static PM_C: string = "PM.C";

    /** 包装方式_更新 */
    /** 更新包装方式 */
    public static PM_U: string = "PM.U";

    /** 锯条厂家_查看 */
    /** 查看锯条厂家 */
    public static SBM_R: string = "SBM.R";

    /** 锯条厂家_创建 */
    /** 创建锯条厂家 */
    public static SBM_C: string = "SBM.C";

    /** 锯条厂家_更新 */
    /** 更新锯条厂家 */
    public static SBM_U: string = "SBM.U";

    /** 机械_查看 */
    /** 查看机械 */
    public static M_R: string = "M.R";

    /** 机械_创建 */
    /** 创建机械 */
    public static M_C: string = "M.C";

    /** 机械_更新 */
    /** 更新机械 */
    public static M_U: string = "M.U";

    /** 机械类型_查看 */
    /** 查看机械类型 */
    public static MT_R: string = "MT.R";

    /** 机械类型_创建 */
    /** 创建机械类型 */
    public static MT_C: string = "MT.C";

    /** 机械类型_更新 */
    /** 更新机械类型 */
    public static MT_U: string = "MT.U";

    /** 标签_查看 */
    /** 查看标签 */
    public static TG_R: string = "TG.R";

    /** 标签_创建 */
    /** 创建标签 */
    public static TG_C: string = "TG.C";

    /** 标签_更新 */
    /** 更新标签 */
    public static TG_U: string = "TG.U";

    /** 用户_查看 */
    /** 查看用户 */
    public static U_R: string = "U.R";

    /** 用户_同步角色 */
    /** 同步角色和用户 */
    public static U_SYNC: string = "U.SYNC";

    /** 用户_仪表板密码 */
    /** 更新仪表板密码 */
    public static U_UDBUPWD: string = "U.UDBUPWD";

    /** 用户_所属工厂_更新 */
    /** 更新用户所属工厂 */
    public static U_F_U: string = "U.F.U";

    /** 客户等级_查看 */
    /** 查看客户等级 */
    public static CG_R: string = "CG.R";

    /** 客户等级_创建 */
    /** 创建客户等级 */
    public static CG_C: string = "CG.C";

    /** 客户等级_更新 */
    /** 更新客户等级 */
    public static CG_U: string = "CG.U";

    /** 客户类型_查看 */
    /** 查看客户类型 */
    public static CT_R: string = "CT.R";

    /** 客户类型_创建 */
    /** 创建客户类型 */
    public static CT_C: string = "CT.C";

    /** 客户类型_更新 */
    /** 更新客户类型 */
    public static CT_U: string = "CT.U";

    /** 其它收费类型_查看 */
    /** 查看其它收费类型 */
    public static APT_R: string = "APT.R";

    /** 其它收费类型_创建 */
    /** 创建其它收费类型 */
    public static APT_C: string = "APT.C";

    /** 其它收费类型_更新 */
    /** 更新其它收费类型 */
    public static APT_U: string = "APT.U";

    /** 银行账户_查看 */
    /** 查看银行账户 */
    public static BA_R: string = "BA.R";

    /** 银行账户_创建 */
    /** 创建银行账户 */
    public static BA_C: string = "BA.C";

    /** 银行账户_更新 */
    /** 更新银行账户 */
    public static BA_U: string = "BA.U";

    /** 工厂_查看 */
    /** 查看工厂 */
    public static F_R: string = "F.R";

    /** 工厂_创建 */
    /** 创建工厂 */
    public static F_C: string = "F.C";

    /** 工厂_更新 */
    /** 更新工厂 */
    public static F_U: string = "F.U";

    /** 图像设备_查看 */
    /** 查看图像设备 */
    public static ID_R: string = "ID.R";

    /** 图像设备_创建 */
    /** 创建图像设备 */
    public static ID_C: string = "ID.C";

    /** 图像设备_更新 */
    /** 更新图像设备 */
    public static ID_U: string = "ID.U";

    /** 图像设备_连接信息_管理员_查看 */
    /** 查看管理员连接信息（图像设备） */
    public static ID_CI_AD_R: string = "ID.CI.AD.R";

    /** 图像设备_连接信息_操作员_查看 */
    /** 查看操作员连接信息（图像设备） */
    public static ID_CI_OP_R: string = "ID.CI.OP.R";

    /** 基础数据_查看 */
    /** 查看基础数据 */
    public static BD_R: string = "BD.R";

    /** 参考价_在库存_更新 */
    /** 更新在库存参考价 */
    public static UP_SI_U: string = "UP.SI.U";

    /** 参考价_全部_更新 */
    /** 更新全部参考价 */
    public static UP_ALL_U: string = "UP.ALL.U";

    /** 扎_等级_查看 */
    /** 查看扎等级 */
    public static SB_G_R: string = "SB.G.R";
}

export default Perm;
